<template>
	<div class="media">
		<div class="mr-3" v-if="icon || text">
			<span class="material-icons" v-if="icon">{{icon}}</span>
			<span class="font-weight-bold" v-else>{{text}}</span>
		</div>
		<div class="media-body">
			<slot></slot>
		</div>
	</div>
</template>
<script type="text/javascript">
	export default {
		props: ['icon', 'text']
	}
</script>